<template>
  <div>
    <div id="knives-rules" class="knives-id"></div>
    <intersect @enter="getAnchor('rules')" @leave="getAnchor(null)">
      <div class="knives__rules">
        <div class="container">
          <img
            class="knives__rules-logo"
            src="@/assets/img/icons/knives-catalog-logo.svg"
            alt=""
          />
          <div class="knives__rules-text">
            {{ $t("knives.rulesText") }}
          </div>
          <a
            :href="promo.attributes.link_to_rules"
            download
            class="knives__rules-btn"
            >{{ $t("knives.rulesDownload") }}</a
          >
        </div>
      </div>
    </intersect>
  </div>
</template>

<script>
import Intersect from "vue-intersect";
import { mapActions, mapState } from "vuex";

import { actionTypes } from "@/store/modules/knives";

export default {
  name: "KnivesRules",
  components: {
    Intersect,
  },
  computed: {
    ...mapState("knivesModule", ["promo"]),
  },
  methods: {
    ...mapActions("knivesModule", {
      getAnchor: actionTypes.getAnchor,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
